import React, { Component } from 'react'
import styled, { css } from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
// import Logo from '../../components/Logo'

// Styled Components
const GlobalHeaderContainer = styled.div`
  background-color: #000;
  border-bottom: 1px solid rgba(255,255,255, 0.1);
  clear: both;
  display: inline-block;
  float: none;
  position: relative;
  width: 100%;
  z-index: 4;

  ${queryBelow("bigPhone", css`
    display: none;
  `)}
`

const GlobalHeaderNav = styled.nav`
  display: flex;
  margin-left: auto;

  a {
    color: #fff;
    display: inline-block;
    font-size: .875em;
    padding: 0.8em 1em;
    position: relative;

    &:last-of-type {
      margin-right: -1em;
    }
  }
`

// Header
class GlobalHeader extends Component {
  render() {
    // const { number, title } = this.props;

    return(
      <GlobalHeaderContainer>
        <div className="wrap">
          <GlobalHeaderNav>
            <a href='https://balancegrille.com' target="_blank" rel="noreferrer">BalanceGrille.com</a>
            <a href='https://balance.catering' target="_blank" rel="noreferrer">Catering</a>
            <a href='https://balancegrille.com/franchise' target="_blank" rel="noreferrer">Franchise</a>
            <a href='https://balancefarms.com/' target="_blank" rel="noreferrer">Farms</a>
          </GlobalHeaderNav>
        </div>
      </GlobalHeaderContainer>
    );
  }
}

export default GlobalHeader;
