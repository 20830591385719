import React, { Component } from 'react'
import styled, { css } from 'styled-components'
// import { Link } from "react-router-dom";

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import Title from '../Title';

// Styled Components
const HalalBadgeContainer = styled.div`
  align-items: center;
  background-color: rgba(255,255,255, 0.06);
  border-radius: 1000px;
  color: ${colors.foreground};
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-start;
  width: fit-content;

  .title {
    color: ${colors.foreground};
    margin-bottom: 0
  }

  svg {
    height: 1.6rem;
    width: 1.6rem;

    &, use {
      fill: ${colors.foreground};
    }
  }
`

// Header
class HalalBadge extends Component {
  render() {
    // const { number, title } = this.props;

    return(
      <HalalBadgeContainer>
        <svg><use xlinkHref="#halal"/></svg>
        <Title className="title" text="Humane. Halal." />
      </HalalBadgeContainer>
    );
  }
}

export default HalalBadge;
